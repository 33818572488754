import './recipe-hero-teaser-list.scss';

// import { Slider } from '../../components/slider/slider';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';

class RecipeHeroTeaserList {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-recipeheroteaserlist'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$recipeHeroTeaserList = $element;
        this.$$recipeHeroTeaserListSlider = this.$recipeHeroTeaserList.querySelectorAll('[' + this.settings.initAttr + '="slider"]');
        this.$$recipeHeroTeaserListSlides = this.$recipeHeroTeaserList.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.$$recipeHeroTeaserListTabs = this.$recipeHeroTeaserList.querySelectorAll('[data-recipeheroteaserlist-tab]');
        this.slider = null;
        this.activeCategory = null;
        this.$activeSlide = null;

        if (this.$$recipeHeroTeaserListSlider.length > 0) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        this.getActiveTab();
        this.initSlider();
        this.setEvents();
    }

    getActiveTab () {
        const $acitveTab = this.$recipeHeroTeaserList.querySelector('[data-recipeheroteaserlist-tab].is--active');
        if ($acitveTab) {
            this.activeCategory = $acitveTab.getAttribute('data-recipeheroteaserlist-tab');
        }
    }

    setInviewClass () {
        if (window.allowAnimation === true) {
            const $$inviewItems = this.$activeSlide.querySelectorAll('[data-inview]');
            if ($$inviewItems.length > 0) {
                for (let index = 0; index < $$inviewItems.length; index++) {
                    $$inviewItems[index].classList.add('inview');
                }
            }
        }
    }

    initSlider () {
        if (this.$$recipeHeroTeaserListSlider.length > 0) {
            for (let index = 0; index < this.$$recipeHeroTeaserListSlider.length; index++) {
                const $$slides = this.$$recipeHeroTeaserListSlider[index].querySelectorAll('[' + this.settings.initAttr + '="slide"]');
                if ($$slides.length > 0) {
                    this.slider = new this.Slider(this.$$recipeHeroTeaserListSlider[index], {
                        modules: [A11y, Navigation, Pagination, Scrollbar],
                        speed: 500,
                        slidesPerView: 'auto',
                        slidesPerGroup: 1,
                        centeredSlidesBounds: true,
                        initAttr: this.settings.initAttr,
                        centerInsufficientSlides: true,
                        centeredSlides: true,
                        fractionText: '•',
                        scrollbar: true,
                        scrollbarAppendToControls: true,
                        counter: true,
                        counterCustom: true,
                        onSlideChange: (slider, $current, currentIndex) => {
                            this.$activeSlide = currentIndex;
                            this.setInviewClass();
                        }
                    });
                }

                if (this.$$recipeHeroTeaserListSlider[index].getAttribute('data-recipeheroteaserlist-category') !== this.activeCategory) {
                    this.$$recipeHeroTeaserListSlider[index].classList.add('is--inactive');
                }
            }
        }
    }

    toggleContent (target) {
        // Change nav state
        for (let index = 0; index < this.$$recipeHeroTeaserListTabs.length; index++) {
            if (this.$$recipeHeroTeaserListTabs[index] !== target) {
                this.$$recipeHeroTeaserListTabs[index].classList.remove('is--active');
            } else {
                this.$$recipeHeroTeaserListTabs[index].classList.add('is--active');
            }
        }

        // Change slides
        for (let index = 0; index < this.$$recipeHeroTeaserListSlider.length; index++) {
            if (this.$$recipeHeroTeaserListSlider[index].getAttribute('data-recipeheroteaserlist-category') === this.activeCategory) {
                this.$$recipeHeroTeaserListSlider[index].classList.remove('is--inactive');
                this.$activeSlide = this.$$recipeHeroTeaserListSlider[index].querySelector('.recipe-hero-teaser-list__slide');
                if (this.$activeSlide) {
                    this.setInviewClass();
                }
            } else {
                this.$$recipeHeroTeaserListSlider[index].classList.add('is--inactive');
            }
        }
    }

    setEvents () {
        if (this.$$recipeHeroTeaserListTabs.length > 0) {
            this.$$recipeHeroTeaserListTabs.forEach(tab => {
                tab.addEventListener('click', (e) => {
                    this.activeCategory = e.target.getAttribute('data-recipeheroteaserlist-tab');
                    this.toggleContent(e.target);
                });
            });
        }
    }
}

export { RecipeHeroTeaserList };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$recipeHeroTeaserList = $context.querySelectorAll('[data-recipeheroteaserlist="root"]');
        for (let i = 0; i < $$recipeHeroTeaserList.length; i++) {
            const $recipeHeroTeaserList = $$recipeHeroTeaserList[i];

            const recipeHeroTeaserAPI = new RecipeHeroTeaserList($recipeHeroTeaserList);
            $recipeHeroTeaserList.API = recipeHeroTeaserAPI;
        }
    }
});
